import { useGlobalContentStore } from '~/store/globalContent';

export default function useLocalDate() {
  const globalContent = useGlobalContentStore();

  const getLocaleDate = (date: string, options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }) => {
    const convert = new Date(date);
    return convert.toLocaleDateString(globalContent.getLocale, options);
  };

  return {
    getLocaleDate,
  };
}
